import React, { useEffect, useState } from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonNote, IonInput, IonSelect, IonSelectOption, IonDatetime, IonDatetimeButton, IonPopover, IonButton, useIonToast } from "@ionic/react"
import MemberStays from './MemberStays';
import MemberCredits from './MemberCredits';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import styled from 'styled-components';
import { format } from 'date-fns';
interface MemberCardProps {
    member: any
}

const MemberInput = styled(IonInput)`
    text-align: right;
`

const DEFAULT_DATE = new Date('2021-1-11').toISOString()

const MemberCard: React.FC<MemberCardProps> = ({ member }) => {

    const [present] = useIonToast();
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [activeMembership, setActiveMembership] = useState<boolean>(true);
    const [membershipExpiration, setMembershipExpiration] = useState<string>(DEFAULT_DATE);
    const [activePass, setActivePass] = useState<boolean>(false);
    const [passExpiration, setPassExpiration] = useState<string>(DEFAULT_DATE);

    useEffect(() => {
        if (member) {
            console.log('MemberCard member', member)
            setFirstName(member.FIRST_NAME);
            setLastName(member.LAST_NAME);
            setEmail(member.EMAIL);
            setPhone(member.PHONE);
            setActiveMembership(member.ACTIVE_MEMBERSHIP || false);
            setMembershipExpiration(member.MEMBERSHIP_EXPIRATION || DEFAULT_DATE);
            setActivePass(member.ACTIVE_PASS || false);
            setPassExpiration(member.PASS_EXPIRATION || DEFAULT_DATE);
        }
    }, [member])


    const handleMembershipExpiration = (e: any) => {
        console.log('Setting membership expiration date: ', e.detail.value)
        setMembershipExpiration(e.detail.value);
    }

    const handlePassExpiration = (e: any) => {
        console.log('Setting pass expiration date: ', e.detail.value)
        setPassExpiration(e.detail.value);
    }

    const saveChanges = async () => {
        const update = {
            FIRST_NAME: firstName || null,
            LAST_NAME: lastName || null,
            EMAIL: email || null, // todo - validate email
            PHONE: phone || null, // todo - format phone number
            ACTIVE_MEMBERSHIP: activeMembership || false,
            MEMBERSHIP_EXPIRATION: activeMembership && membershipExpiration ? format(new Date(membershipExpiration), 'yyyy-MM-dd') : '', // clear the date if membership inactive
            ACTIVE_PASS: activePass || false,
            PASS_EXPIRATION: activePass && passExpiration ? format(new Date(passExpiration), 'yyyy-MM-dd') : '' // clear the date if pass inactive
        }

        // todo assert update is valid

        console.warn('update', update)

        const ref = doc(db, `MEMBERS/${member._UID}`);
        try {
            await setDoc(ref, update, { merge: true });
            present({
                message: 'Updated Member Info',
                position: 'top',
                duration: 2000,
                color: 'primary'
            })
        } catch (e: any) {
            console.error(e)
            present({
                message: `Error: ${e.message}`,
                position: 'top',
                duration: 2000,
                color: 'danger'
            })
        }
    }

    return (
        <>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>Member Info</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonItem>
                        <IonLabel>First Name</IonLabel>
                        <MemberInput slot="end" value={firstName} onIonChange={(e) => e.detail.value ? setFirstName(e.detail.value) : setFirstName('')} />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Last Name</IonLabel>
                        <MemberInput slot="end" value={lastName} onIonChange={(e) => e.detail.value ? setLastName(e.detail.value) : setLastName('')} />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Email</IonLabel>
                        <MemberInput slot="end" value={email} onIonChange={(e) => e.detail.value ? setEmail(e.detail.value) : setEmail('')} />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Phone</IonLabel>
                        <MemberInput slot="end" value={phone} onIonChange={(e) => e.detail.value ? setPhone(e.detail.value) : setPhone('')} />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Active Membership</IonLabel>
                        <IonSelect value={activeMembership} onIonChange={(e) => e.detail.value ? setActiveMembership(e.detail.value) : setActiveMembership(false)}>
                            <IonSelectOption value={true}>Yes</IonSelectOption>
                            <IonSelectOption value={false}>No</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    {activeMembership && (
                        <IonItem>
                            <IonLabel>Membership Expiration</IonLabel>
                            <IonDatetimeButton datetime="membershipExpiration" slot="end"></IonDatetimeButton>
                            <IonPopover keepContentsMounted={true}>
                                <IonDatetime  max="2025-12-31T23:59:59" value={membershipExpiration} onIonChange={handleMembershipExpiration} presentation="date" id="membershipExpiration"></IonDatetime>
                            </IonPopover>
                        </IonItem>
                    )}
                    <IonItem>
                        <IonLabel>Active Pass</IonLabel>
                        <IonSelect value={activePass} onIonChange={(e) => e.detail.value ? setActivePass(e.detail.value) : setActivePass(false)}>
                            <IonSelectOption value={true}>Yes</IonSelectOption>
                            <IonSelectOption value={false}>No</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    {activePass && (
                        <IonItem>
                            <IonLabel>Pass Expiration</IonLabel>
                            <IonDatetimeButton datetime="passExpiration" slot="end"></IonDatetimeButton>
                            <IonPopover keepContentsMounted={true}>
                                <IonDatetime max="2025-12-31T23:59:59" value={passExpiration} onIonChange={handlePassExpiration} presentation="date" id="passExpiration"></IonDatetime>
                            </IonPopover>
                        </IonItem>
                        
                    )}
                    <IonButton expand="block" style={{ marginTop: '20px' }} onClick={saveChanges}>Save Changes</IonButton>
                    <p style={{ marginTop: '20px' }}>Member Integration Identifiers {`(don't change)`}</p>
                    <IonItem>
                        <IonLabel>Hubspot ID</IonLabel>
                        <IonNote>{member.HUBSPOT_ID}</IonNote>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Planyo ID</IonLabel>
                        <IonNote>{member.PLANYO_ID}</IonNote>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Stripe ID</IonLabel>
                        <IonNote>{member.STRIPE_ID}</IonNote>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Database ID</IonLabel>
                        <IonNote>{member._UID}</IonNote>
                    </IonItem>
                </IonCardContent>
            </IonCard>

            <MemberStays member={member} />
            <MemberCredits member={member} />
        </>
    )
}

export default MemberCard;