import React from "react";
import { DocumentData, collection, query } from "firebase/firestore";
import { db } from "../config/firebase";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonLoading,
  IonToast,
  IonListHeader,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonChip,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useEffect, useState } from "react";
import MemberCard from "../components/MemberCard";
import { refresh } from "ionicons/icons";
import CreateMember from "../components/CreateMember";
import { Member } from "../models/member.model";
import { format } from "date-fns";

interface MembersProps {
  admin: any | null;
}

const MembersPage: React.FC<MembersProps> = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null | undefined>("");

  const memberRef = query(collection(db, "MEMBERS"));
  const { isError, data, isLoading } = useFirestoreQuery(
    ["members"],
    memberRef,
    {
      subscribe: true,
    }
  );

  useEffect(() => {
    if (data) {
      const members = data.docs.map((doc: DocumentData) => ({
        ...(doc.data() as Member),
        _UID: doc.id,
      }));
      setMembers(members);
      setFilteredMembers(members);
    }
  }, [data]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = members.filter((x: any) => {
        const fullName = `${x.FIRST_NAME} ${x.LAST_NAME}`.toLowerCase();
        return fullName.includes(searchQuery.toLowerCase());
      });
      setFilteredMembers(filtered);
    } else {
      setFilteredMembers(members);
    }
  }, [members, searchQuery]);

  const handleFilterDropdown = (e: any) => {
    const value = e.target.value;
    switch (value) {
      case 'active': {
        const active = members.filter((x: any) => x.ACTIVE_MEMBERSHIP);
        setFilteredMembers(active);
        break;
      }
      case 'inactive': {
        const inactive = members.filter((x: any) => !x.ACTIVE_MEMBERSHIP);
        setFilteredMembers(inactive);
        break;
      }
      case 'expiringSoon': {
        const expiringSoon = members.filter((x: any) => {
          const expiration = new Date(x.MEMBERSHIP_EXPIRATION);
          const today = new Date();
          const diff = expiration.getTime() - today.getTime();
          const days = diff / (1000 * 3600 * 24);
          return days <= 30 && days > 0;
        });
        setFilteredMembers(expiringSoon);
        break;
      }
      case 'recentlyExpired': {
        const recentlyExpired = members.filter((x: any) => {
          const expiration = new Date(x.MEMBERSHIP_EXPIRATION);
          const today = new Date();
          const diff = expiration.getTime() - today.getTime();
          const days = diff / (1000 * 3600 * 24);
          return days <= 0;
        });
        setFilteredMembers(recentlyExpired);
        break;
      }
      default:
        setFilteredMembers(members);
        break;
    }

  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            {/* <IonTitle size="large">Members</IonTitle> */}
          </IonToolbar>
        </IonHeader>
        <IonLoading backdropDismiss={true} isOpen={isLoading} />
        <IonToast
          isOpen={isError}
          message="Error talking to the internet. ChatGPT has been notified"
        />
        <IonGrid>
          <IonRow>
            <IonCol size="4">
              <div
                style={{
                  height: "calc(100vh - 80px)",
                  overflowY: "scroll",
                  position: "fixed",
                  width: "300px",
                }}
              >
                <IonList>
                  <div
                    style={{ marginBottom: "5px", paddingLeft: 0 }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <IonLabel>
                        <h2 style={{ fontSize: '1.5rem', fontWeight: '700' }}>Members ({filteredMembers.length})</h2>
                      </IonLabel>
                      <CreateMember />
                    </div>
                    <div>
                      <IonSearchbar
                        style={{ maxWidth: "90%" }}
                        value={searchQuery}
                        onIonInput={(e) => setSearchQuery(e.detail.value)}
                      />
                    </div>
                    <div>
                      <IonItem>
                        <IonSelect label="Status" onIonChange={handleFilterDropdown}>
                          <IonSelectOption value="all">All</IonSelectOption>
                          <IonSelectOption value="active">Active</IonSelectOption>
                          <IonSelectOption value="expiringSoon">Expiring Soon</IonSelectOption>
                          <IonSelectOption value="recentlyExpired">Recently Expired</IonSelectOption>
                          <IonSelectOption value="inactive">Inactive</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </div>
                  </div>
                  {filteredMembers.map((x: Member) => (
                    <IonItem
                      color={x._UID === selectedMember?._UID ? "primary" : ""}
                      style={{ borderRadius: "10px" }}
                      key={x._UID}
                      detail
                      onClick={() => setSelectedMember(x)}
                    >
                      <IonLabel>
                        {x.FIRST_NAME} {x.LAST_NAME}
                      </IonLabel>
                      <IonChip color={x.ACTIVE_MEMBERSHIP ? 'success' : 'danger'}>
                        {x.MEMBERSHIP_EXPIRATION ? <IonLabel>{x.ACTIVE_MEMBERSHIP === null ? format(new Date(x.MEMBERSHIP_EXPIRATION), 'MMM dd, yyyy') : 'Inactive'}</IonLabel> : <IonLabel color="dark">Missing Expiration</IonLabel>}
                        
                      </IonChip>
                    </IonItem>
                  ))}
                </IonList>
              </div>
            </IonCol>
            <IonCol size="8">
              {selectedMember && (
                <>
                  <MemberCard member={selectedMember} />
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MembersPage;
