
import React, { useEffect, useState } from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonSpinner, IonChip } from "@ionic/react"
import { DocumentData, collection, orderBy, query, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useFirestoreQuery } from '@react-query-firebase/firestore';
import { format, isAfter, isBefore, setHours, setMinutes, setSeconds } from 'date-fns';

interface MemberCardProps {
    member: any
}

const MemberStays: React.FC<MemberCardProps> = ({ member }) => {

    const staysRef = query(collection(db, 'STAYS'), where('MEMBER_ID', '==', member._UID), orderBy('START_DATE', 'desc'));
    const { data, isLoading } = useFirestoreQuery(['memberStays', { member }], staysRef, {
        subscribe: false,
    });

    const [stays, setStays] = useState<any[]>([]); // eslint-disable-line

    useEffect(() => {
        if (data) {
            const now = setHours(setMinutes(setSeconds(new Date(), 0), 0), 0);
            const stays = data.docs.map((snapshot: DocumentData) => {
                const doc = snapshot.data() as any;
                const START_DATE = new Date(`${doc.START_DATE} 00:00:00`);
                const END_DATE = new Date(`${doc.END_DATE} 00:00:00`);
                return {
                    ...doc,
                    _UID: snapshot.id,
                    START_DATE,
                    END_DATE,
                    isCancelled: doc.status > 7,
                    isFuture: isAfter(START_DATE, now),
                    isPast: isBefore(END_DATE, now),
                    isCurrent: isBefore(START_DATE, now) && isAfter(END_DATE, now)
                }
            })
            console.warn('stays: ', stays)
            setStays(stays);
        }
    }, [data])

    const openInPlanyo = (reservationId: string) => {
        window.open(`https://www.planyo.com/rental.php?id=${reservationId}`, '_blank');
    }


    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>Stays ({stays.filter((x) => !x.isCancelled).length})
                    {isLoading && (
                        <IonSpinner style={{ float: 'right' }} />
                    )}
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {stays.filter((x: any) => !x.isCancelled).map((x: any) => (
                    <IonItem key={x._UID} style={{ cursor: 'pointer' }} detail onClick={() => openInPlanyo(x.RESERVATION_ID)}>
                        <IonLabel>

                            <p>{format(x.START_DATE, 'MMM d')} - {format(x.END_DATE, 'MMM d, yyyy')}</p>
                            <div>{x.RESOURCE_NAME}</div>
                        </IonLabel>
                        {x.isFuture && (
                            <IonChip color="warning">
                                <IonLabel>Future</IonLabel>
                            </IonChip>
                        )}
                        {x.isPast && (
                            <IonChip color="success">
                                <IonLabel>Past</IonLabel>
                            </IonChip>
                        )}
                        {x.isCurrent && (
                            <IonChip color="primary">
                                <IonLabel>Current</IonLabel>
                            </IonChip>
                        )}
                    </IonItem>
                ))}
            </IonCardContent>
            <IonCardHeader>
                <IonCardTitle>Cancellations
                    {isLoading && (
                        <IonSpinner style={{ float: 'right' }} />
                    )}
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {stays.filter((x: any) => x.isCancelled).map((x: any) => (
                    <IonItem key={x._UID} style={{ cursor: 'pointer' }} detail onClick={() => openInPlanyo(x.RESERVATION_ID)}>
                        <IonLabel>
                            <p>{format(x.START_DATE, 'MMM d')} - {format(x.END_DATE, 'MMM d, yyyy')}</p>
                            <div>{x.RESOURCE_NAME}</div>
                        </IonLabel>
                        <IonChip color="danger">
                            <IonLabel>Cancelled</IonLabel>
                        </IonChip>

                    </IonItem>
                ))}
            </IonCardContent>
        </IonCard>
    )

}

export default MemberStays;