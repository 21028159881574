import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonList, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonInput } from "@ionic/react";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { collection, doc, query, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../config/firebase";
import { Site } from "../models/site.model";
import styled from "styled-components";

const SiteInput = styled(IonInput)`
    text-align: right;
`

const SitesPage: React.FC = () => {

    const sitesRef = query(collection(db, 'SITES'));
    const { data } = useFirestoreQuery(['sites'], sitesRef, {
        subscribe: true,
    });

    const [sites, setSites] = useState<Site[]>([]);
    const [selectedSite, setSelectedSite] = useState<Site | null>(null);

    const [capacity, setCapacity] = useState<number>(0);
    const [wifiNetwork, setWifiNetwork] = useState<string>('');
    const [wifiPassword, setWifiPassword] = useState<string>('');
    const [siteWelcomeVideoUrl, setSiteWelcomeVideoUrl] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [gateCode, setGateCode] = useState<string>('');
    const [liaisonName, setLiaisonName] = useState<string>('');
    const [liaisonEmail, setLiaisonEmail] = useState<string>('');
    const [liaisonPhone, setLiaisonPhone] = useState<string>('');


    useEffect(() => {
        if (data) {
            setSites(data.docs.map((doc) => ({ ...doc.data() as Site, _UID: doc.id })));
        }
    }, [data])

    useEffect(() => {
        if (selectedSite) {
            setCapacity(selectedSite.CAPACITY);
            setWifiNetwork(selectedSite.WIFI_NETWORK);
            setWifiPassword(selectedSite.WIFI_PASSWORD);
            setSiteWelcomeVideoUrl(selectedSite.SITE_WELCOME_VIDEO_URL || '');
            setAddress(selectedSite.ADDRESS);
            setGateCode(selectedSite.GATE_CODE || '');
            setLiaisonName(selectedSite.LIAISON_NAME);
            setLiaisonEmail(selectedSite.LIAISON_EMAIL);
            setLiaisonPhone(selectedSite.LIAISON_PHONE);
        }
    }, [selectedSite])

    const saveChanges = async () => {

        if (!selectedSite) return;

        const update = {
            CAPACITY: capacity,
            WIFI_NETWORK: wifiNetwork,
            WIFI_PASSWORD: wifiPassword,
            SITE_WELCOME_VIDEO_URL: siteWelcomeVideoUrl,
            ADDRESS: address,
            GATE_CODE: gateCode,
            LIAISON_NAME: liaisonName,
            LIAISON_EMAIL: liaisonEmail,
            LIAISON_PHONE: liaisonPhone
        }
        console.log('selected site: ', update)

        // update doc in firebase
        const ref = doc(db, `SITES/${selectedSite._UID}`);
        try {
            await setDoc(ref, update, { merge: true });
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Sites</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Sites</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid>
                    <IonRow>
                        <IonCol size="3">
                            <IonList>

                                {sites.map((site: Site) => (
                                    <IonItem key={site._UID} onClick={() => setSelectedSite(site)} detail>
                                        <IonLabel>{site.NAME}</IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                        </IonCol>
                        <IonCol size="9">
                            {selectedSite && (
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>{selectedSite.NAME}</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonItem>
                                            <IonLabel color="medium">
                                                Address
                                            </IonLabel>
                                            <SiteInput slot="end" value={address} onIonChange={(e) => e.detail.value ? setAddress(e.detail.value) : setAddress('')} />
                                        </IonItem>
                                        <IonItem>
                                            <IonLabel color="medium">Gate Code</IonLabel>
                                            <SiteInput slot="end" value={gateCode} onIonChange={(e) => e.detail.value ? setGateCode(e.detail.value) : setGateCode('')} />
                                        </IonItem>
                                        {/* <IonItem>
                                            <IonLabel color="medium">Capacity</IonLabel>
                                            <SiteInput type="number" slot="end" value={capacity} onIonChange={(e) => e.detail.value ? setCapacity(parseInt(e.detail.value)) : setCapacity(0)} />
                                        </IonItem> */}
                                        <IonItem>
                                            <IonLabel>Wifi Network</IonLabel>
                                            <SiteInput slot="end" value={wifiNetwork} onIonChange={(e) => e.detail.value ? setWifiNetwork(e.detail.value) : setWifiNetwork('')} />
                                        </IonItem>
                                        <IonItem>
                                            <IonLabel>Wifi Password</IonLabel>
                                            <SiteInput slot="end" value={wifiPassword} onIonChange={(e) => e.detail.value ? setWifiPassword(e.detail.value) : setWifiPassword('')} />
                                        </IonItem>
                                        <IonItem>
                                            <IonLabel>Site Welcome Video</IonLabel>
                                            {selectedSite.SITE_WELCOME_VIDEO_URL ? (
                                                <IonButton slot="end">View on YouTube</IonButton>
                                            ) : (
                                                <SiteInput placeholder="Enter YouTube Video URL" slot="end" value={siteWelcomeVideoUrl} onIonChange={(e) => e.detail.value ? setSiteWelcomeVideoUrl(e.detail.value) : setSiteWelcomeVideoUrl('')} />
                                            )}

                                        </IonItem>
                                        <IonItem>
                                            <IonLabel>Liaison</IonLabel>
                                            <SiteInput slot="end" value={liaisonName} onIonChange={(e) => e.detail.value ? setLiaisonName(e.detail.value) : setLiaisonName('')} />
                                        </IonItem>
                                        <IonItem>
                                            <IonLabel>Liaison Phone</IonLabel>
                                            <SiteInput slot="end" value={liaisonPhone} onIonChange={(e) => e.detail.value ? setLiaisonPhone(e.detail.value) : setLiaisonPhone('')} />
                                        </IonItem>
                                        <IonItem>
                                            <IonLabel>Liaison Email</IonLabel>
                                            <SiteInput slot="end" value={liaisonEmail} onIonChange={(e) => e.detail.value ? setLiaisonEmail(e.detail.value) : setLiaisonEmail('')} />
                                        </IonItem>
                                        <IonButton expand="block" onClick={saveChanges}>Save Changes</IonButton>
                                    </IonCardContent>
                                </IonCard>
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )

}

export default SitesPage;