import React, { useEffect } from 'react';
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useHistory, useLocation } from 'react-router-dom';
import { albums, albumsOutline, home, homeOutline, logOut, peopleCircle, peopleCircleOutline } from 'ionicons/icons';
import './Menu.css';
import { getAuth, signOut } from 'firebase/auth';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

interface MenuProps {
  admin: any | null;
  access: 'admin' | 'liaison' | null;
}

const appPages: AppPage[] = [
  {
    title: 'Members',
    url: '/members',
    iosIcon: peopleCircleOutline,
    mdIcon: peopleCircle
  },
  {
    title: 'Stays',
    url: '/stays',
    iosIcon: albumsOutline,
    mdIcon: albums
  },
  {
    title: 'Sites',
    url: '/sites',
    iosIcon: homeOutline,
    mdIcon: home
  }
];



const Menu: React.FC<MenuProps> = ({ admin, access }) => {
  const location = useLocation();
  const history = useHistory();

  const [displayedPages, setDisplayedPages] = React.useState<AppPage[]>(appPages);


  const logout = () => {
    const auth = getAuth();
    signOut(auth);
    history.push('/');
  }

  useEffect(() => {
    if(access !== 'admin') {
        setDisplayedPages(appPages.filter((appPage) => appPage.title !== 'Members'));
    } else {
        setDisplayedPages(appPages);
    }
  }, [access])


  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Kift Dashbaord</IonListHeader>
          {admin && (<IonNote>{admin.email}</IonNote>)}

          {displayedPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <IonItem style={{ marginTop: '100px' }} onClick={logout}>
            <IonLabel>Logout</IonLabel>
            <IonIcon slot="end" icon={logOut} />
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
