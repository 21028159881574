import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonNote,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { DocumentData, collection, orderBy, query } from "firebase/firestore";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import {
  setHours,
  setMinutes,
  setSeconds,
  isBefore,
  format,
  isAfter,
} from "date-fns";
import { db } from "../config/firebase";
import { refresh, open, addCircle } from "ionicons/icons";
import { getFunctions, httpsCallable } from "firebase/functions";
import CreateStay from "../components/CreateStay";

interface StaysProps {
  admin: any | null;
}

const StaysPage: React.FC<StaysProps> = () => {
  // eslint-disable-line

  const [allStays, setAllStays] = useState<any[]>([]);
  const [filteredStays, setFilteredStays] = useState<any[]>([]);
  const [selectedStay, setSelectedStay] = useState<any | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null | undefined>("");
  const [segment, setSegment] = useState<
    "all" | "current" | "future" | "past" | "cancelled"
  >("current");
  const [updatingStay, setUpdatingStay] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const stayRef = query(collection(db, "STAYS"), orderBy("START_DATE", "desc"));
  const { data, refetch, isLoading, isError } = useFirestoreQuery(
    ["stays"],
    stayRef,
    {
      subscribe: true,
    }
  );

  useEffect(() => {
    if (data) {
      console.log("data: ", data);
      const now = setHours(setMinutes(setSeconds(new Date(), 0), 0), 0);
      const stays = data.docs.map((snapshot: DocumentData) => {
        const doc = snapshot.data() as any;
        const START_DATE = new Date(`${doc.START_DATE} 00:00:00`);
        const END_DATE = new Date(`${doc.END_DATE} 23:59:59`);
        return {
          ...doc,
          _UID: snapshot.id,
          START_DATE,
          END_DATE,
          isCancelled: doc.status > 7,
          isFuture: isAfter(START_DATE, now),
          isPast: isBefore(END_DATE, now),
          isCurrent: isBefore(START_DATE, now) && isAfter(END_DATE, now),
        };
      });

      if (selectedStay) {
        console.log("have existing selected stay, ", selectedStay);
        const selected = stays.find((x: any) => x._UID === selectedStay._UID);
        if (selected) {
          console.log("found selected stay, ", selected);
          setSelectedStay(selected);
        }
      }
      console.warn("stays: ", stays);
      setAllStays(stays);
    }
  }, [data, selectedStay]);

  useEffect(() => {
    console.log("selected stay, ", selectedStay);
  }, [selectedStay]);

  useEffect(() => {
    if (searchQuery) {
      let filteredStays = allStays.filter(
        (x: any) =>
          x.MEMBER_NAME.toLowerCase().includes(searchQuery.toLowerCase()) ||
          x.RESOURCE_NAME.toLowerCase().includes(
            searchQuery.toLowerCase() ||
              x.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
              x.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              x.last_name.toLowerCase().includes(searchQuery.toLowerCase())
          )
      );

      switch (segment) {
        case "all":
          break;
        case "current":
          filteredStays = filteredStays.filter(
            (x: any) => x.isCurrent && !x.isCancelled
          );
          break;
        case "future":
          filteredStays = filteredStays.filter(
            (x: any) => x.isFuture && !x.isCancelled
          );
          break;
        case "past":
          filteredStays = filteredStays.filter(
            (x: any) => x.isPast && !x.isCancelled
          );
          break;
        case "cancelled":
          filteredStays = filteredStays.filter((x: any) => x.isCancelled);
          break;
        default:
          break;
      }
      setFilteredStays(filteredStays);
    } else {
      setFilteredStays(allStays);
    }
  }, [searchQuery, allStays, segment]);

  useEffect(() => {
    let filteredStays = [];
    if (searchQuery) {
      filteredStays = allStays.filter(
        (x: any) =>
          x.MEMBER_NAME.toLowerCase().includes(searchQuery.toLowerCase()) ||
          x.RESOURCE_NAME.toLowerCase().includes(
            searchQuery.toLowerCase() ||
              x.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
              x.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              x.last_name.toLowerCase().includes(searchQuery.toLowerCase())
          )
      );
    } else {
      filteredStays = allStays;
    }

    switch (segment) {
      case "all":
        break;
      case "current":
        filteredStays = filteredStays.filter(
          (x: any) => x.isCurrent && !x.isCancelled
        );
        break;
      case "future":
        filteredStays = filteredStays.filter(
          (x: any) => x.isFuture && !x.isCancelled
        );
        break;
      case "past":
        filteredStays = filteredStays.filter(
          (x: any) => x.isPast && !x.isCancelled
        );
        break;
      case "cancelled":
        filteredStays = filteredStays.filter((x: any) => x.isCancelled);
        break;
      default:
        break;
    }
    setFilteredStays(filteredStays);
    // setSelectedStay(null);
  }, [segment, allStays, searchQuery]);

  const openInPlanyo = (reservationId: string) => {
    window.open(
      `https://www.planyo.com/rental.php?id=${reservationId}`,
      "_blank"
    );
  };

  const openInFirebase = (uid: string) => {
    window.open(
      `https://console.firebase.google.com/u/3/project/kift-members/firestore/data/~2FSTAYS~2F${uid}`,
      "_blank"
    );
  };

  const refreshFromPlanyo = async (
    firebaseId: string,
    reservationId: string
  ) => {
    setUpdatingStay(true);
    const functions = getFunctions();
    const refreshReservation = httpsCallable(
      functions,
      "planyo-refreshReservation"
    );
    const result: any = await refreshReservation({ firebaseId, reservationId });

    console.log("result: ", result);
    if (result.data) {
      refetch();
    }
    setUpdatingStay(false);
  };

  return (
    <IonPage>
      <IonLoading backdropDismiss={true} isOpen={isLoading} />
      <IonToast
        isOpen={isError}
        message="Error talking to the internet. ChatGPT has been notified"
      />
      <IonHeader>
        <IonToolbar>
          <IonTitle>Stays</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Stays</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeLg="6" offsetLg="2">
              <IonSearchbar
                value={searchQuery}
                onIonInput={(e) => setSearchQuery(e.detail.value)}
              />
            </IonCol>
            <IonCol size="12" sizeLg="2">
              <IonButton onClick={() => setShowModal(true)}>
                <IonIcon slot="start" icon={addCircle} />
                <IonLabel>Make Reservation</IonLabel>
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <IonSegment
                value={segment}
                onIonChange={(e) => setSegment(e.detail.value as any)}
              >
                <IonSegmentButton value="current">
                  <IonLabel>Current</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="future">
                  <IonLabel>Future</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="past">
                  <IonLabel>Past</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="all">
                  <IonLabel>All</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="cancelled">
                  <IonLabel>Cancelled</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <div
                style={{
                  height: "calc(100vh - 220px)",
                  overflowY: "scroll",
                  position: "fixed",
                  width: "400px",
                  marginTop: "10px",
                }}
              >
                <IonList>
                  {filteredStays.map((x: any) => (
                    <IonItem
                      color={x._UID === selectedStay?._UID ? "primary" : ""}
                      style={{ borderRadius: "10px" }}
                      key={x._UID}
                      onClick={() => setSelectedStay(x)}
                      detail
                    >
                      <IonLabel>
                        <h2>{x.MEMBER_NAME}</h2>
                        <p>
                          {format(x.START_DATE, "MMM d")} -{" "}
                          {format(x.END_DATE, "MMM d, yyyy")}
                        </p>
                      </IonLabel>
                      <IonChip slot="end">
                        <IonLabel>{x.RESOURCE_NAME}</IonLabel>
                      </IonChip>
                    </IonItem>
                  ))}
                </IonList>
              </div>
            </IonCol>
            <IonCol size="7">
              {selectedStay && (
                <IonCard>
                  <IonCardContent>
                    <IonItem>
                      <IonLabel>Member</IonLabel>
                      <IonNote>{selectedStay.MEMBER_NAME}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Email</IonLabel>
                      <IonNote>{selectedStay.EMAIL}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Location</IonLabel>
                      <IonChip color="dark">
                        <IonLabel>{selectedStay.RESOURCE_NAME}</IonLabel>
                      </IonChip>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Dates </IonLabel>
                      <IonNote>
                        {format(selectedStay.START_DATE, "MMM d")} -{" "}
                        {format(selectedStay.END_DATE, "MMM d, yyyy")}
                      </IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Membership Type</IonLabel>
                      <IonChip>
                        <IonLabel style={{ textTransform: "capitalize" }}>
                          {selectedStay.MEMBERSHIP_TYPE}
                        </IonLabel>
                      </IonChip>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Status</IonLabel>
                      <IonNote>{selectedStay.status}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Last Updated</IonLabel>
                      <IonNote>
                        {format(
                          selectedStay.LAST_UPDATED?.toDate(),
                          "MMM d, yyyy hh:mm"
                        )}
                      </IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Database Id</IonLabel>
                      <IonNote>{selectedStay._UID}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Planyo Id</IonLabel>
                      <IonNote>{selectedStay.RESERVATION_ID}</IonNote>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Open in Planyo</IonLabel>
                      <IonButton
                        fill="clear"
                        onClick={() =>
                          openInPlanyo(selectedStay.RESERVATION_ID)
                        }
                      >
                        <IonIcon slot="icon-only" icon={open} />
                      </IonButton>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Open in Database</IonLabel>
                      <IonButton
                        fill="clear"
                        onClick={() => openInFirebase(selectedStay._UID)}
                      >
                        <IonIcon slot="icon-only" icon={open} />
                      </IonButton>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Refresh Planyo Data</IonLabel>
                      <IonButton
                        fill="clear"
                        onClick={() =>
                          refreshFromPlanyo(
                            selectedStay._UID,
                            selectedStay.RESERVATION_ID
                          )
                        }
                      >
                        {updatingStay ? (
                          <IonSpinner />
                        ) : (
                          <IonIcon slot="icon-only" icon={refresh} />
                        )}
                      </IonButton>
                    </IonItem>
                  </IonCardContent>
                </IonCard>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Make Reservation</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <CreateStay setShowModal={setShowModal} />
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default StaysPage;
