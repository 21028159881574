/**
 * this just needs to be imported somewhere
 * then it'll be initialized globally and accesible throughout the app
 */
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';


console.warn('Env: ', process.env.REACT_APP_ENV);

// const config = {
//         apiKey: 'AIzaSyAJH32wHab34C2DuBFfDPIiFG-MC3KRkcU',
//         authDomain: 'kift-members.firebaseapp.com',
//         projectId: 'kift-members',
//         storageBucket: 'kift-members.appspot.com',
//         messagingSenderId: '1086716221750',
//         appId: '1:1086716221750:web:960c5a48b99a4e4804bb97',
//         measurementId: 'G-YJ14VWTQ6S'
//       }

const config =
  process.env.REACT_APP_ENV === 'production'
    ? {
        apiKey: 'AIzaSyAJH32wHab34C2DuBFfDPIiFG-MC3KRkcU',
        authDomain: 'kift-members.firebaseapp.com',
        projectId: 'kift-members',
        storageBucket: 'kift-members.appspot.com',
        messagingSenderId: '1086716221750',
        appId: '1:1086716221750:web:960c5a48b99a4e4804bb97',
        measurementId: 'G-YJ14VWTQ6S'
      }
    : {
        apiKey: 'AIzaSyBrZLH3us7xMXtIIztaR-opHO63fg6LbRs',
        authDomain: 'kift-members-dev.firebaseapp.com',
        projectId: 'kift-members-dev',
        storageBucket: 'kift-members-dev.appspot.com',
        messagingSenderId: '1010085798947',
        appId: '1:1010085798947:web:857078568d0487e707fd96',
        measurementId: 'G-6M87JMPXDX'
      };

console.warn('Firebase config: ', config)

const app = initializeApp(config);

export const functions = getFunctions(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
