import React from 'react';
import { User, UserCredential, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { auth } from "../config/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import useToastError from "../hooks/useToastError";
import { IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonItem, IonLabel, IonInput, IonButton, IonSpinner } from "@ionic/react";

import './Login.css'
import { useHistory } from 'react-router-dom';

interface LoginProps {
    setUser: (user: User | null) => void;
    setAccess: (access: 'admin' | 'liaison') => void;
}

const LoginPage: React.FC<LoginProps> = ({ setUser, setAccess }) => {
    const history = useHistory();
    const displayError = useToastError();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const signIn = async () => {
        setLoading(true);
        if (email && password) {

            const functions = getFunctions();
            const checkAdmin = httpsCallable(functions, 'admin-isAdmin');
            const result: any = await checkAdmin({ email }); 
            console.warn('Admin roles: ', result);
            if (result.data.isAdmin) {
                signInWithEmailAndPassword(auth, email, password)
                    .then((user: UserCredential) => {
                        setAccess(result.data.role)
                        setUser(user.user)
                        setLoading(false)
                        history.push('/stays');
                    })
                    .catch((error) => {
                        console.error(error)
                        displayError(error)
                        setLoading(false)
                    });
            } else {
                displayError({ message: 'You are not authorized to access this app.' })
                setLoading(false)
            }
        } else {
            displayError({ message: 'Missing email or password' })
            setLoading(false)
        }
    }


    return (
        <>
            <IonCard className="login-card">
                <IonCardTitle>
                    <IonCardHeader>Welcome Kift Admin</IonCardHeader>
                </IonCardTitle>
                <IonCardContent>
                    <IonItem fill="outline">
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput
                            value={email}
                            onIonInput={(e: any) => setEmail(e.detail.value!)} // eslint-disable-line
                        />
                    </IonItem>

                    <IonItem fill="outline">
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput
                            value={password}
                            type="password"
                            onIonInput={(e: any) => setPassword(e.detail.value!)} // eslint-disable-line
                            clearInput
                        />
                    </IonItem>

                    <IonButton style={{ marginTop: '20px' }} shape="round" expand="block" onClick={signIn}>Sign In
                        {loading && <IonSpinner slot="end" style={{ marginLeft: '20px' }} />}</IonButton>

                </IonCardContent>
            </IonCard>
        </>
    )

}

export default LoginPage;