import React from 'react';
import { IonSplitPane, IonRouterOutlet } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"
import { Route, Redirect } from "react-router"
import Menu from "./components/Menu"
import { User } from "firebase/auth"
import { useEffect, useState } from "react"
import { auth, db } from "./config/firebase"
import LoginPage from "./pages/Login"
import MembersPage from './pages/Members';
import StaysPage from './pages/Stays';
import SitesPage from './pages/Sites';
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { collection, query, where } from "firebase/firestore";

const Dashboard: React.FC = () => {
    const [user, setUser] = useState<User | null>(null); // eslint-disable-line
    const [userEmail, setUserEmail] = useState<string | null>(null); // eslint-disable-line
    const [access, setAccess] = useState<'admin' | 'liaison' | null>('liaison');

    const ref = query(collection(db, 'ADMINS'), where("EMAIL", "==", userEmail));

    // The Query Key now reflects the query constraints
    const {data: accessData} = useFirestoreQueryData(["userAccess", { userEmail }], ref, {subscribe: false});

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                console.log('User: ', user);
                setUser(user);
                // setUserEmail(user.email);
            } else {
                setUser(null);
                setUserEmail(null);
            }
        });
    }, []);

    useEffect(() => {
        if (user) {
            console.log('setting user email:', user.email)
            setUserEmail(user.email);
        }
    }, [user])

    useEffect(() => {
        if (accessData && accessData.length > 0) {
            console.log('Admin access: ', accessData);
            setAccess(accessData[0].ROLE);
        }
    }, [accessData]);

    return (
        <IonReactRouter>
            {user ? (
                
                    <IonSplitPane contentId="main">
                        <Menu admin={user} access={access} />
                        <IonRouterOutlet id="main">
                            <Route exact path="/members">
                                <MembersPage admin={user} />
                            </Route>
                            <Route exact path="/stays">
                                <StaysPage admin={user} />
                            </Route>
                            <Route exact path="/sites">
                                <SitesPage />
                            </Route>
                        </IonRouterOutlet>
                    </IonSplitPane>
    
            ) : (
                <IonRouterOutlet>
                    <Route path="/login">
                        <LoginPage setUser={setUser} setAccess={setAccess} />
                    </Route>
                    <Redirect to="/login" />
                </IonRouterOutlet>
            )}

        </IonReactRouter>
    )

}

export default Dashboard;