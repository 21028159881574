import React from 'react';
import { IonApp, IonButton, IonContent, IonPage, isPlatform, setupIonicReact } from '@ionic/react';
import { QueryClient, QueryClientProvider } from 'react-query'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { addDoc, collection } from 'firebase/firestore';
import { db } from './config/firebase';
import { ErrorBoundary } from 'react-error-boundary';
import Dashboard from './Dashboard';
import { BrowserRouter } from 'react-router-dom';

setupIonicReact({
  mode: isPlatform('android') ? 'md' : 'ios',
});

const Fallback = (props: any) => {
  const { error, resetErrorBoundary } = props;
  return (
    <IonPage>
      <IonContent className="ion-padding" style={{ textAlign: 'center' }}>
        <p>Something went wrong...</p>
        <p>{error.message}</p>
        <IonButton expand="block" color="dark" shape="round" onClick={resetErrorBoundary} > Try Again </IonButton>
      </IonContent>
    </IonPage>
  );
}

const logError = async (error: Error, info: any) => {

  const { message, stack } = error;

  console.error(error, info);
  await addDoc(collection(db, 'ERRORS'), {
    message,
    stack,
    info: info ? info.componentStack : null
  })
}

const App: React.FC = () => {
  const queryClient = new QueryClient()
  return (
    <IonApp>
      <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <Dashboard />
          </QueryClientProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </IonApp>
  );
};

export default App;
