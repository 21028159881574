import { DocumentData, collection, query } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../config/firebase";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { IonButton, IonCol, IonDatetime, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonSpinner, useIonToast } from "@ionic/react";
import { differenceInCalendarDays, format, isAfter, isBefore, isSameDay } from "date-fns";
import { getFunctions, httpsCallable } from "firebase/functions";

interface CreateStayProps { 
    setShowModal: (show: boolean) => void;
}

const CreateStay: React.FC<CreateStayProps> = ({setShowModal}) => {

    const [present] = useIonToast();
    const startDatetimeRef = useRef<null | HTMLIonDatetimeElement>(null);
    const endDatetimeRef = useRef<null | HTMLIonDatetimeElement>(null);
    const [members, setMembers] = useState<any[]>([]);
    const [selectedMember, setSelectedMember] = useState<any | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [numberOfNights, setNumberOfNights] = useState<number>(0);
    const [startDateLabel, setStartDateLabel] = useState<string>("Select start date");
    const [endDateLabel, setEndDateLabel] = useState<string>("Select end date");
    const [fetchingResources, setFetchingResources] = useState<boolean>(false);
    const [resources, setResources] = useState<any[]>([]);
    const [selectedResource, setSelectedResource] = useState<any | null>(null);
    const [guestFirstName, setGuestFirstName] = useState<string>('');
    const [guestLastName, setGuestLastName] = useState<string>('');
    const [creatingStay, setCreatingStay] = useState<boolean>(false);

    // fetch all members
    const memberRef = query(collection(db, 'MEMBERS'));
    const { data, isLoading: loadingMembers } = useFirestoreQuery(['members'], memberRef, {
        subscribe: false,
    });

    // fetch all locations
    useEffect(() => {
        getResources();
    }, [])

    // process members
    useEffect(() => {
        if (data) {
            const members = data.docs.map((doc: DocumentData) => ({
                _UID: doc.id,
                ...doc.data() as any
            }));
            setMembers(members);
        }

    }, [data])

    // calculate number of nights when start or end date changes
    useEffect(() => {
        if (!startDate || startDate === '') return;
        if (!endDate || endDate === '') return;
        const start = new Date(startDate);
        const end = new Date(endDate);
        const nights = differenceInCalendarDays(end, start)
        setNumberOfNights(nights);
    }, [startDate, endDate, setNumberOfNights])



    const getResources = () => {
        setFetchingResources(true);
        const functions = getFunctions();
        httpsCallable(functions, 'planyo-listResources')()
            .then((result: any) => {
                console.warn('Resource Fetch Result: ', result)
                const { data, error } = result;
                if (error) {
                    throw new Error(error)
                } else {
                    setResources(data);
                }
            }).finally(() => {
                setFetchingResources(false);
            });
    }

    const handleStartDatePicker = (e: any) => {
        if (!e.detail.value) return;
        const zeroedDate = format(new Date(e.detail.value), 'yyyy-MM-dd')
        setStartDateLabel(format(new Date(e.detail.value), 'MMM dd, yyyy'))
        setStartDate(zeroedDate)

        if (endDate && isAfter(new Date(zeroedDate), new Date(endDate))) {
            setEndDate('');
            setEndDateLabel('Select a date')
        }
    }

    const handleEndDatePicker = (e: any) => {
        if (!e.detail.value) return;
        const zeroedDate = format(new Date(e.detail.value), 'yyyy-MM-dd')
        setEndDateLabel(format(new Date(e.detail.value), 'MMM dd, yyyy'))
        setEndDate(zeroedDate)

        if (startDate && isBefore(new Date(zeroedDate), new Date(startDate))) {
            setStartDate('');
            setStartDateLabel('Select a date')
        }
    }

    const highlightDates = (isoString: string) => {
        if (!startDate || startDate === '') return undefined;
        if (startDate && (!endDate || endDate === '')) {
            if (isSameDay(new Date(startDate), new Date(isoString))) {
                return {
                    textColor: 'var(--ion-color-secondary-contrast)',
                    backgroundColor: 'var(--ion-color-secondary)',
                };
            }
        }
        if (!startDate || startDate === '' || !endDate || endDate === '') return undefined;

        const date = new Date(isoString);
        const isSameOrAfter = isSameDay(date, new Date(startDate)) || isAfter(date, new Date(startDate));
        const isSameOfBefore = isSameDay(date, new Date(endDate)) || isBefore(date, new Date(endDate));
        if (isSameOrAfter && isSameOfBefore) {
            return {
                textColor: 'var(--ion-color-primary-contrast)',
                backgroundColor: 'var(--ion-color-primary)',
            };
        }
        return undefined;
    }

    const handleCreateStay = async () => {
        if (!selectedMember || !selectedResource || !startDate || !endDate) {
            alert('Select a Member, Location, Start & End Dates')
            return;
        }

        if(selectedMember === 'guest' && (!guestFirstName || !guestLastName)) {
            alert('Enter Guest First & Last Name')
            return;
        }

        setCreatingStay(true);
        const functions = getFunctions();
        // ignore availability for now, admin reservations are made using "god mode"
        // const availabilityParams = {
        //     resourceId: selectedResource.id,
        //     startDate: `${startDate} 00:00:00`,
        //     endDate: `${endDate} 23:59:59`,
        // }
        // console.warn('Availability Params: ', availabilityParams)
        // const checkAvailability = httpsCallable(functions, 'planyo-checkAvailability')
        // const availabilityResponse = await checkAvailability(availabilityParams)
        // const { is_reservation_possible, problem_time, reason } = availabilityResponse.data as any;
        // console.log('Availability Response: ', availabilityResponse)

        // if (!is_reservation_possible) {
        //     alert(`Reservation not possible: ${reason} :: ${problem_time}`)
        //     setCreatingStay(false);
        //     return;
        // }

        console.log('creating stay...')
        httpsCallable(functions, 'admin-makeReservation')({
            isAnonymous: selectedMember === 'guest',
            memberId: selectedMember._UID || null,
            memberEmail: selectedMember.EMAIL || null,
            memberFirstName: guestFirstName || null,
            memberLastName: guestLastName || null,
            resourceId: selectedResource.id,
            resourceName: selectedResource.name,
            startDate,
            endDate,
        })
            .then((result: any) => {
                console.warn('Create Stay Result: ', result)
                const { data } = result;
                if (data.error) {
                    setCreatingStay(false);
                    console.error('Error Creating Stay: ', data.error)
                    present({
                        message: 'Error Creating Stay',
                        position: 'top',
                        duration: 5000,
                        color: 'danger'
                    })
                } else {
                    present({
                        message: 'Stay Created',
                        position: 'top',
                        duration: 2000,
                        color: 'primary'
                    })
                    setStartDate('');
                    setStartDateLabel('Select a date')
                    setEndDate('');
                    setEndDateLabel('Select a date')
                    setSelectedResource(null);
                    setSelectedMember(null);
                    setGuestFirstName('');
                    setGuestLastName('');
                    setShowModal(false);
                }
            }).finally(() => {
                setCreatingStay(false);
            });
    }


    return (
        <>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            {loadingMembers ? (<IonSpinner />) : (
                                <IonSelect label="Select Member" onIonChange={(e: any) => setSelectedMember(e.detail.value)}>
                                    <IonSelectOption value={'guest'}>Guest</IonSelectOption>
                                    {members.map((x: any) => (<IonSelectOption key={x._UID} value={x}>{x.FIRST_NAME} {x.LAST_NAME}</IonSelectOption>))}
                                </IonSelect>
                            )}
                        </IonItem>
                        {selectedMember === 'guest' && (
                            <>
                                <IonItem>
                                    <IonLabel>Guest First Name</IonLabel>
                                    <IonInput value={guestFirstName} onIonChange={(e: any) => setGuestFirstName(e.detail.value)}></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Guest Last Name</IonLabel>
                                    <IonInput value={guestLastName} onIonChange={(e: any) => setGuestLastName(e.detail.value)}></IonInput>
                                </IonItem>
                            </>
                        )}

                        <IonItem>
                            {fetchingResources ? (<IonSpinner />) : (
                                <IonSelect label="Select Site" onIonChange={(e: any) => setSelectedResource(e.detail.value)}>
                                    {resources.map((x: any) => (<IonSelectOption key={x.id} value={x}>{x.name}</IonSelectOption>))}
                                </IonSelect>
                            )}
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <div>
                            <p>Start Date</p>
                            <h4>{startDateLabel}</h4>
                        </div>
                        <IonDatetime
                            style={{ margin: '0 auto' }}
                            ref={startDatetimeRef}
                            preferWheel={false}
                            color="primary"
                            highlightedDates={highlightDates}
                            presentation="date"
                            onIonChange={(e) => handleStartDatePicker(e)}
                        />
                    </IonCol>
                    <IonCol size="6">
                        <div>
                            <p>End Date</p>
                            <h4>{endDateLabel}</h4>
                        </div>
                        <IonDatetime
                            style={{ margin: '0 auto' }}
                            ref={endDatetimeRef}
                            preferWheel={false}
                            color="primary"
                            highlightedDates={highlightDates}
                            presentation="date"
                            onIonChange={(e) => handleEndDatePicker(e)}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Number of nights</IonLabel>
                            <IonLabel slot="end">{numberOfNights}</IonLabel>
                        </IonItem>
                        <IonButton 
                        disabled={creatingStay} 
                        style={{ marginTop: '20px' }} 
                        expand="block" 
                        onClick={handleCreateStay}>
                            <IonLabel>Book Stay</IonLabel>
                            {creatingStay && (<IonSpinner slot="end" />)}
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );

}

export default CreateStay;