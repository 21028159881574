
import React, { useEffect, useRef, useState } from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonSpinner, IonModal, IonHeader, IonButton, IonButtons, IonContent, IonInput, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react"
import { DocumentData, addDoc, collection, orderBy, query, serverTimestamp, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useFirestoreQuery } from '@react-query-firebase/firestore';
import { format } from 'date-fns';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import useSuccessToast from '../hooks/useSuccessToast';

interface MemberCreditsProps {
    member: any
}

const MemberCredits: React.FC<MemberCreditsProps> = ({ member }) => {
    const successToast = useSuccessToast()
    const ref = query(collection(db, 'CREDITS'), where('MEMBER_ID', '==', member._UID), orderBy('DATE_ISSUED', 'desc'));
    const { data, isLoading, refetch } = useFirestoreQuery(['memberCredits', { member }], ref, {
        subscribe: true,
    });

    const [credits, setCredits] = useState<any[]>([]);

    const modal = useRef<HTMLIonModalElement>(null);


    const [note, setNote] = useState<string>('');
    const [creditType, setCreditType] = useState<string>('');
    const [reservationId, setReservationId] = useState<string>('');
    const [numberOfCredits, setNumberOfCredits] = useState<number>(1);

    function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
        if (ev.detail.role === 'confirm') {
            // setMessage(`Hello, ${ev.detail.data}!`);
        }
    }

    async function createCredit() {
        console.warn('createCredit: ', note, creditType, numberOfCredits)

        const writes = [];

        for (let i = 0; i < numberOfCredits; i++) {
            console.log('creating ith credit: ', i)
            writes.push(addDoc(collection(db, 'CREDITS'), {
                MEMBER_ID: member._UID,
                TYPE: 'NIGHT',
                DATE_ISSUED: serverTimestamp(),
                REDEEMED: false,
                REDEEMED_RESERVATION_ID: reservationId ? reservationId : null,
                REDEEMED_DATE: null,
                ISSUED_RESERVATION_ID: null,
                MONETARY_VALUE: 2500,
                ISSUED_SOURCE: creditType ? creditType : 'ADMIN',
                NOTE: note ? note : null,
            }))
        }

        const result = await Promise.all(writes)

        console.log('result: ', result)

        modal.current?.dismiss({
            'dismissed': true
        });



        successToast('Credit created')


    }

    useEffect(() => {
        if (data) {

            const credits = data.docs.map((snapshot: DocumentData) => {
                const doc = snapshot.data() as any;
                if (snapshot.metadata.hasPendingWrites) {
                    return {
                        ...doc,
                        DATE_ISSUED: new Date(),
                        _UID: snapshot.id,
                    }
                } else {
                    return {
                        ...doc,
                        DATE_ISSUED: doc.DATE_ISSUED.toDate(),
                        _UID: snapshot.id,

                    }
                }

            })
            console.warn('credits: ', credits)
            setCredits(credits);
        }
    }, [data])


    return (
        <>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>Available Credits ({credits.filter((x: any) => !x.REDEEMED).length})
                        {isLoading && (
                            <IonSpinner style={{ float: 'right' }} />
                        )}
                        <IonButton onClick={() => refetch()} style={{ float: 'right' }}>
                            Refresh
                        </IonButton>
                        <IonButton id="create-credit-modal" style={{ float: 'right' }}>
                            Create
                            <IonModal ref={modal} trigger="create-credit-modal" onWillDismiss={(ev) => onWillDismiss(ev)}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => modal.current?.dismiss()}>Cancel</IonButton>
                        </IonButtons>
                        <IonTitle>Create a Credit</IonTitle>
                        <IonButtons slot="end">
                            {/* <IonButton strong={true} onClick={() => confirm()}>
                                Confirm
                            </IonButton> */}
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">

                    <IonItem>
                        <IonLabel position="stacked">Number of Credits</IonLabel>
                        <IonInput value={numberOfCredits} type="number" placeholder="(required)" onIonInput={(e: any) => setNumberOfCredits(e.detail.value)} />
                    </IonItem>

                    <p>The following fields are optional:</p>

                    <IonItem>

                        <IonSelect label="Credit Type" aria-label="credit type" placeholder="Select type" interface="popover" onIonChange={(e: any) => setCreditType(e.detail.value)}>
                            <IonSelectOption value="REFUND">Refund</IonSelectOption>
                            <IonSelectOption value="MONTHLY_SUBSCRIPTION">Monthly Subscription</IonSelectOption>
                            <IonSelectOption value="ADMIN">Other</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Linked Reservation Id</IonLabel>
                        <IonInput placeholder="(optional)" onIonInput={(e: any) => setReservationId(e.detail.value)} />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Add any notes</IonLabel>
                        <IonTextarea placeholder="(optional)" onIonInput={(e: any) => setNote(e.detail.value)} rows={5} fill="outline" style={{ '--background': 'var(--ion-color-light-tint)', '--border-radius': '10px', '--padding-top': '10px', '--padding-start': '10px' }} />
                    </IonItem>
                    <IonButton expand="block" onClick={createCredit}>Create</IonButton>
                </IonContent>
            </IonModal>
                        </IonButton>
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    {credits.filter((x: any) => !x.REDEEMED).map((x: any) => (
                        <IonItem key={x._UID}>
                            <IonLabel>
                                <p>Issued: {format(x.DATE_ISSUED, 'MMM dd, yyyy')}</p>
                                <div>{x.ISSUED_SOURCE}</div>
                            </IonLabel>

                        </IonItem>
                    ))}
                </IonCardContent>

                <IonCardHeader>
                    <IonCardTitle>Redeemed Credits ({credits.filter((x: any) => x.REDEEMED).length})
                        {isLoading && (
                            <IonSpinner style={{ float: 'right' }} />
                        )}
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    {credits.filter((x: any) => x.REDEEMED).map((x: any) => (
                        <IonItem key={x._UID}>
                            <IonLabel>
                                <p>Issued: {format(x.DATE_ISSUED, 'MMM dd, yyyy')}</p>
                                <div>{x.ISSUED_SOURCE}</div>
                            </IonLabel>

                        </IonItem>
                    ))}
                </IonCardContent>

            </IonCard>
        </>
    )

}

export default MemberCredits;