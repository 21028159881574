import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import React, { useCallback, useRef, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { add } from "ionicons/icons";

const CreateMember: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const firstNameInput = useRef<HTMLIonInputElement>(null);
  const lastNameInput = useRef<HTMLIonInputElement>(null);
  const emailInput = useRef<HTMLIonInputElement>(null);
  const phoneInput = useRef<HTMLIonInputElement>(null);

  const valid = useRef(false);

  const [activeMembership, setActiveMembership] = useState<boolean>(true);
  const [membershipExpiration, setMembershipExpiration] = useState<string>(
    new Date().toISOString()
  );
  const [activePass, setActivePass] = useState<boolean>(false);
  const [passExpiration, setPassExpiration] = useState<string>(
    new Date().toISOString()
  );
  const [confirmedValues, setConfirmedValues] = useState<boolean>(false);
  const [signedWaiver, setSignedWaiver] = useState<boolean>(false);

  const [present] = useIonToast();

  const handleValidation = useCallback((): {
    message: string;
    color: string;
  } => {
    if (
      !firstNameInput.current?.value ||
      !lastNameInput.current?.value ||
      !emailInput.current?.value ||
      !phoneInput.current?.value
    ) {
      valid.current = false;

      return {
        message: "Can't add member without all fields populated",
        color: "danger",
      };
    }
    valid.current = true;
    return { message: "Member Added", color: "primary" };
  }, []);

  const addMember = useCallback(() => {
    /**
         * admin-createMember expects the following params:
         *   const {
                firstName,
                lastName,
                email,
                phone,
                activeMembership,
                membershipExpiration,
                activePass,
                passExpiration,
                confirmedValues,
                signedWaiver
            } = data;
         */

    const payload = {
      firstName: firstNameInput.current?.value,
      lastName: lastNameInput.current?.value,
      email: emailInput.current?.value,
      phone: phoneInput.current?.value,
      activeMembership,
      membershipExpiration,
      activePass,
      passExpiration,
      confirmedValues: confirmedValues,
      signedWaiver: signedWaiver,
    };

    console.log("Creating member from: ", payload);

    const functions = getFunctions();
    httpsCallable(
      functions,
      "admin-createMember"
    )(payload)
      .then((result: any) => {
        console.warn("Create member Result: ", result);
        const { data, error } = result;
        if (error) {
          throw new Error(error);
        } else {
          console.warn("Create member response: ", data);
        }
      })
      .finally(() => {
        console.log("done");
      });
  }, [
    activeMembership,
    activePass,
    confirmedValues,
    membershipExpiration,
    passExpiration,
    signedWaiver,
  ]);

  const confirm = useCallback(() => {
    handleValidation();
    if (valid.current) {
      addMember();
      modal.current?.dismiss(firstNameInput.current?.value, "confirm");
    }
    present({
      message: handleValidation().message,
      position: "top",
      duration: 2000,
      color: handleValidation().color,
    });
  }, [addMember, handleValidation, present]);

  const handleMembershipExpiration = (e: any) => {
    console.log("Setting membership expiration date: ", e.detail.value);
    setMembershipExpiration(e.detail.value);
  };

  const handlePassExpiration = (e: any) => {
    console.log("Setting pass expiration date: ", e.detail.value);
    setPassExpiration(e.detail.value);
  };

  return (
    <>
      <IonButton id="open-modal">
        {/* <IonLabel>Create Member</IonLabel> */}
        <IonIcon icon={add} />
      </IonButton>

      <IonModal ref={modal} trigger="open-modal">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Welcome</IonTitle>
            <IonButtons slot="end">
              <IonButton strong={true} onClick={() => confirm()}>
                Save
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>
            <IonLabel position="stacked">Member First name</IonLabel>
            <IonInput
              ref={firstNameInput}
              type="text"
              placeholder="Albert"
              required
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Member Last name</IonLabel>
            <IonInput ref={lastNameInput} type="text" placeholder="Einstein" />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Member Email</IonLabel>
            <IonInput
              ref={emailInput}
              type="text"
              placeholder="alberteinstein@gmail.com"
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Member Phone</IonLabel>
            <IonInput ref={phoneInput} type="text" placeholder="123-456-7890" />
          </IonItem>

          <IonItem>
            <IonLabel>Active Membership</IonLabel>
            <IonSelect
              value={activeMembership}
              onIonChange={(e) =>
                e.detail.value
                  ? setActiveMembership(e.detail.value)
                  : setActiveMembership(false)
              }
            >
              <IonSelectOption value={true}>Yes</IonSelectOption>
              <IonSelectOption value={false}>No</IonSelectOption>
            </IonSelect>
          </IonItem>
          {activeMembership && (
            <IonItem>
              <IonLabel>Membership Expiration</IonLabel>
              <IonDatetimeButton
                datetime="membershipExpiration"
                slot="end"
              ></IonDatetimeButton>
              <IonPopover keepContentsMounted={true}>
                <IonDatetime
                  value={membershipExpiration}
                  onIonChange={handleMembershipExpiration}
                  presentation="date"
                  id="membershipExpiration"
                ></IonDatetime>
              </IonPopover>
            </IonItem>
          )}
          <IonItem>
            <IonLabel>Active Pass</IonLabel>
            <IonSelect
              value={activePass}
              onIonChange={(e) =>
                e.detail.value
                  ? setActivePass(e.detail.value)
                  : setActivePass(false)
              }
            >
              <IonSelectOption value={true}>Yes</IonSelectOption>
              <IonSelectOption value={false}>No</IonSelectOption>
            </IonSelect>
          </IonItem>
          {activePass && (
            <IonItem>
              <IonLabel>Pass Expiration</IonLabel>
              <IonDatetimeButton
                datetime="passExpiration"
                slot="end"
              ></IonDatetimeButton>
              <IonPopover keepContentsMounted={true}>
                <IonDatetime
                  value={passExpiration}
                  onIonChange={handlePassExpiration}
                  presentation="date"
                  id="passExpiration"
                ></IonDatetime>
              </IonPopover>
            </IonItem>
          )}
          <IonItem>
            <IonCheckbox
              labelPlacement="start"
              onIonChange={(e) => setConfirmedValues(e.target.checked)}
            >
              Agreed to Values
            </IonCheckbox>
          </IonItem>
          <IonItem>
            <IonCheckbox
              labelPlacement="start"
              onIonChange={(e) => {
                setSignedWaiver(e.target.checked);
              }}
            >
              Signed Waivers
            </IonCheckbox>
          </IonItem>
        </IonContent>
      </IonModal>
    </>
  );
};

export default CreateMember;
